<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Detalhes da despesa" class="text-left">
      <!-- Tabela Principais -->
      <b-table :items="item" :fields="fields" stacked class="text-left">
        <template v-slot:cell(project_name)="{ item }">
          {{ item.project_code }} - {{ item.project_name }}
        </template>
        <template v-slot:cell(value)="{ item }">
          R$ {{ numberToMonetary(item.value) }}
        </template>
      </b-table>
    </b-card>

    <b-card title="Parcelas / Recorrências">
      <div
        v-if="installments.length <= 0"
        class="d-flex justify-content-center align-items-center"
      >
        <b-alert show variant="primary" style="width: 50vw">
          <p class="text-center p-2">
            Esta despesa não possui recorrência / parcelamento
          </p>
        </b-alert>
      </div>

      <b-table
        v-show="installments.length > 0"
        :items="installments"
        :fields="fieldsInstallments"
        sort-by="transaction_date"
        :sort-direction="sortDirection"
        class="text-left my-2"
        :tbody-tr-class="rowClass"
      >
        <template v-slot:cell(actions)="{ item }">
          <ActionsDetails :item="item" :getItem="getItem" />
        </template>
        <template v-slot:cell(transaction_date)="{ item }">
          {{ dateFormatter(item.transaction_date) }}
        </template>
        <template v-slot:cell(value)="{ item }">
          R$ {{ numberToMonetary(item.value) }}
        </template>
      </b-table>
    </b-card>

    <b-card>
      <div class="d-flex justify-content-end align-items-end">
        <BButton
          variant="secondary"
          class="mr-1"
          @click="$router.go(-1)"
          >Voltar</BButton
        >
        <BButton
          variant="warning"
          @click="$router.push(`/editar-despesa/${$route.params.id}`)"
        >
          {{ installments.length > 0 ? 'Editar em lote' : 'Editar' }}
        </BButton>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable, BButton, BAlert } from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ActionsDetails from './ActionsDetailExpense.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BButton,
    BAlert,
    ActionsDetails,
  },
  data: () => ({
    fields: [
      {
        key: 'project_name',
        label: 'Projeto associado',
        sortable: false,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: false,
      },
      {
        key: 'supplier.supplier_name',
        label: 'Fornecedor',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data da transação',
        sortable: false,
      },
      {
        key: 'reference_date',
        label: 'Mês de referência',
        sortable: false,
      },
      {
        key: 'value',
        label: 'Valor total',
        sortable: false,
      },
      {
        key: 'installments',
        label: 'Parcelado',
        sortable: false,
      },
      {
        key: 'created_at',
        label: 'Criado em',
        sortable: false,
      },
      {
        key: 'updated_at',
        label: 'Ultima modificação',
        sortable: false,
      },
      {
        key: 'description',
        label: 'Observações/detalhes',
        sortable: false,
      },
    ],
    fieldsInstallments: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'value',
        label: 'Valor da parcela',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data da transação',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status da parcela',
        sortable: true,
      },
    ],
    sortDirection: 'asc',
    haveInstallments: false,
    item: [],
    installments: [],
  }),

  created() {
    this.getItem();
  },

  computed: {},

  methods: {
    async getItem() {
      this.item = [];

      this.$store
        .dispatch('getExpense', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          const item = {
            ...resp,
            reference_date: this.dateFormatter(resp.reference_date, 'MM/yyyy'),
            transaction_date: this.dateFormatter(
              resp.transaction_date,
              'dd/MM/yyyy'
            ),
            created_at: this.dateFormatter(resp.created_at),
            updated_at: this.dateFormatter(resp.updated_at),
            installments: resp.installments.length > 0 ? 'Sim' : 'Não',
          };
          this.item.push(item);
          this.haveInstallments = item.installments;
          this.installments = resp.installments;
         
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.status === 'realizado'){
        return 'table-success';
      } else {
        return'table-danger'
      }
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
